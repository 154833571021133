import React, { useEffect } from 'react';
import { MediaQueryProvider } from 'react-media-query-hoc';
import getConfig from 'next/config';
import { GetServerSideProps } from 'next';
import { ApolloProvider, gql, useQuery } from '@apollo/client';
import { getDataFromTree } from '@apollo/client/react/ssr';

import SkipLink from '@domain-group/fe-co-skip-link';
import { tokens } from '@domain-group/fe-brary';

import {
  AgencyProfileMapWithFragment,
  AppraisalCTAWithFragment,
  ContactAboutSectionWithFragment,
  ContactCurrentListings,
  ContactEnquiryFormWithFragment,
  ContactHeadWithQuery,
  ContactHeroWithFragment,
  ContactListingsStoryWithFragment,
  ContactProfileFooterWithFragment,
  ContactProfileHeaderWithFragment,
  ContactRecommendationsWithFragment,
  ContactSalesStatisticsStoryWithFragment,
  ContactSalesStatisticsWithFragment,
  ContactTestimonialsWithFragment,
  ContactTopSummaryWithFragment,
  ContactVideoWithFragment,
  ErrorBoundary,
  InView,
  LoadingPage,
  SectionWrapper,
} from '../../components';
import { ShortlistProvider } from '../../contexts';

import { skipLinkName } from '../../helper/accessibility-utils';
import { addApolloState, initializeApollo } from '../../lib/apollo';
import { getDomainWinstonLogger } from '../../core/logger';
import { COMPONENT_IDS } from '../../constants';
import {
  trackContactAboutSection,
  trackContactAppraisalCta,
  trackContactEnquiryForm,
  trackContactHero,
  trackContactRecommendations,
  trackCurrentListings,
  trackProfilePage,
  trackSalesStats,
} from '../../tracking';
import { getAppraisalCTAUrl, getUserDetails, joinUrls } from '../../utils';

import * as styles from '../../styles/trade-profile.style';
import { isAgencyWithoutAppraisalProcess } from '../../services/feature-flag-services';

const { publicRuntimeConfig } = getConfig();

export const AGENT_PROFILE_QUERY = gql`
  query getContactProfile($contactId: Int!) {
    contactByContactId(contactId: $contactId) {
      id
      agentIdV2
      agencyId
      name
      profileUrl

      ...${ContactProfileFooterWithFragment.fragmentName}
      ...${ContactProfileHeaderWithFragment.fragmentName}
      ...${ContactHeroWithFragment.fragmentName}
      ...${ContactTopSummaryWithFragment.fragmentName}
      ...${ContactAboutSectionWithFragment.fragmentName}
      ...${ContactSalesStatisticsStoryWithFragment.fragmentName}
      ...${ContactSalesStatisticsWithFragment.fragmentName}
      ...${AppraisalCTAWithFragment.fragmentName}
      ...${ContactListingsStoryWithFragment.fragmentName}
      ...${ContactRecommendationsWithFragment.fragmentName}
      ...${ContactTestimonialsWithFragment.fragmentName}
      ...${ContactEnquiryFormWithFragment.fragmentName}
      ...${ContactVideoWithFragment.fragmentName}

      agencyByAgencyId {
        id
        name
        address {
          displayAddress
          state
          suburb {
            id
            suburbId
            name
          }
          postcode
        }

        ...${AgencyProfileMapWithFragment.fragmentName}
      }
    }
  }
  ${ContactProfileFooterWithFragment.fragment}
  ${ContactProfileHeaderWithFragment.fragment}
  ${ContactHeroWithFragment.fragment}
  ${ContactTopSummaryWithFragment.fragment}
  ${ContactAboutSectionWithFragment.fragment}
  ${ContactSalesStatisticsStoryWithFragment.fragment}
  ${ContactSalesStatisticsWithFragment.fragment}
  ${AppraisalCTAWithFragment.fragment}
  ${ContactListingsStoryWithFragment.fragment}
  ${ContactRecommendationsWithFragment.fragment}
  ${ContactTestimonialsWithFragment.fragment}
  ${ContactEnquiryFormWithFragment.fragment}
  ${ContactVideoWithFragment.fragment}
  ${AgencyProfileMapWithFragment.fragment}
`;

interface AgentProfilePageProps {
  contactId: number;
  isEmbeddedApp?: boolean;
  requestAppraisalUrl: string;
  user?: {
    userId: string;
    sessionId: string;
    disableUserTracking: boolean;
    userName: string;
    givenName: string;
    familyName: string;
    postcode: string;
    userToken: string;
    emailHash: string;
    ipHash: string;
  };
  hideAppraisalCta?: boolean;
  mixpanelHasInitialized: boolean;
}

const AgentProfilePage = ({
  isEmbeddedApp = false,
  contactId,
  user,
  requestAppraisalUrl,
  hideAppraisalCta = false,
  mixpanelHasInitialized = false,
}: AgentProfilePageProps): JSX.Element => {
  const { data, loading, error } = useQuery(AGENT_PROFILE_QUERY, {
    variables: { contactId },
    errorPolicy: 'all', // https://www.apollographql.com/docs/react/v2/data/error-handling/#error-policies
  });

  const { agentIdV2, agencyId } = data?.contactByContactId || {};

  useEffect(() => {
    if (mixpanelHasInitialized && data?.contactByContactId) {
      trackProfilePage.trackPageViewAgentProfile({
        locationId:
          data?.contactByContactId?.agencyByAgencyId?.address?.suburb?.slug,
      });
    }
  }, [mixpanelHasInitialized, data?.contactByContactId, contactId]);

  if (error) {
    throw new Error(`Something went wrong, ${error}`);
  }

  if (loading) {
    return <LoadingPage />;
  }

  if (!data || !agentIdV2 || !agencyId) {
    throw new Error('Insufficient data to render Agent Profile page.');
  }

  return (
    <ErrorBoundary>
      <>
        <ContactHeadWithQuery contactId={contactId} user={user} />
        <SkipLink anchorName={skipLinkName} />
        <div
          css={styles.page}
          data-testid="trade-profile"
          data-version={publicRuntimeConfig.version}
        >
          <MediaQueryProvider>
            <ShortlistProvider>
              <ContactHeroWithFragment
                agentIdV2={agentIdV2}
                agencyId={agencyId}
                renderComponent={
                  isEmbeddedApp ? null : (
                    <ContactProfileHeaderWithFragment
                      agentIdV2={agentIdV2}
                      agencyId={agencyId}
                    />
                  )
                }
                emailFormComponentId={COMPONENT_IDS.enquiryForm}
                onAgencyAddressClick={trackContactHero.agencyAddressClick}
                onAgencyLogoClick={trackContactHero.agencyLogoClick}
                onCallButtonClick={() => {
                  trackContactHero.callButtonClick({
                    locationId:
                      data?.contactByContactId?.agencyByAgencyId?.address
                        ?.suburb?.slug,
                  });
                }}
                onEmailButtonClick={trackContactHero.emailButtonClick}
                onSocialClick={() => trackContactHero.trackSocialLink}
                desktopWidth={1232}
              />
              <SectionWrapper isShallow componentId={COMPONENT_IDS.summary}>
                <ContactTopSummaryWithFragment
                  agentIdV2={agentIdV2}
                  agencyId={agencyId}
                />
              </SectionWrapper>
              <SectionWrapper
                isNarrow
                isShallow
                componentId={COMPONENT_IDS.about}
              >
                <ContactAboutSectionWithFragment
                  agentIdV2={agentIdV2}
                  agencyId={agencyId}
                  onReadMore={trackContactAboutSection.trackReadMore}
                />
              </SectionWrapper>
              <SectionWrapper componentId={COMPONENT_IDS.statistics}>
                <ContactSalesStatisticsStoryWithFragment
                  agentIdV2={agentIdV2}
                  agencyId={agencyId}
                />
                <ContactSalesStatisticsWithFragment
                  agentIdV2={agentIdV2}
                  agencyId={agencyId}
                  tracking={{
                    onSuburbChange: trackSalesStats.contact.onSelectSuburb,
                  }}
                />
              </SectionWrapper>
              {!hideAppraisalCta && (
                <SectionWrapper componentId="profile-appraisal-cta">
                  <AppraisalCTAWithFragment
                    contactId={contactId}
                    agentIdV2={agentIdV2}
                    agencyId={agencyId}
                    url={getAppraisalCTAUrl({
                      url: requestAppraisalUrl,
                      agentId: `${contactId}`,
                      entryPoint: 'Agent Profile',
                    })}
                    onClick={() => {
                      trackContactAppraisalCta.trackOnClick({
                        locationId:
                          data?.contactByContactId?.agencyByAgencyId?.address
                            ?.suburb?.slug,
                      });
                    }}
                  />
                </SectionWrapper>
              )}
              <SectionWrapper componentId={COMPONENT_IDS.listings}>
                <ContactListingsStoryWithFragment
                  agentIdV2={agentIdV2}
                  agencyId={agencyId}
                />
                <ContactCurrentListings
                  contactId={contactId}
                  tracking={{
                    onSelectListingStatus:
                      trackCurrentListings.contact.onSelectListingStatus,
                    onSelectSelectSortBy: trackCurrentListings.contact.onSortBy,
                    onSelectPropertyType:
                      trackCurrentListings.contact.onPropertyTypeChange,
                    onSelectSuburb: trackCurrentListings.contact.onSuburbChange,
                    onSelectBedrooms:
                      trackCurrentListings.contact.onBedroomChange,
                    onPageChange: trackCurrentListings.contact.onViewMore,
                    onListingCardClick:
                      trackCurrentListings.contact.onListingCardClick,
                  }}
                  mobilePadding={tokens.spacing.m} // Removes the margin for the picker on mobile
                />
              </SectionWrapper>
              <SectionWrapper componentId={COMPONENT_IDS.recommendations}>
                <ContactRecommendationsWithFragment
                  agentIdV2={agentIdV2}
                  agencyId={agencyId}
                  onPageRendered={
                    trackContactRecommendations.trackContactPageRendered
                  }
                  onVendorRecoViewAll={
                    trackContactRecommendations.trackContactViewAll
                  }
                  onVendorRecoRendered={
                    trackContactRecommendations.trackContactRendered
                  }
                  onVendorRecoImpression={
                    trackContactRecommendations.trackContactImpression
                  }
                  onVendorRecoModalViewMore={
                    trackContactRecommendations.trackContactModalViewMore
                  }
                  onVendorRecoModalPrev={
                    trackContactRecommendations.trackContactModalPrevious
                  }
                  onVendorRecoModalNext={
                    trackContactRecommendations.trackContactModalNext
                  }
                />
              </SectionWrapper>
              <SectionWrapper componentId={COMPONENT_IDS.testimonials}>
                <ContactTestimonialsWithFragment
                  agentIdV2={agentIdV2}
                  agencyId={agencyId}
                />
              </SectionWrapper>
              <SectionWrapper componentId={COMPONENT_IDS.video}>
                <ContactVideoWithFragment
                  agentIdV2={agentIdV2}
                  agencyId={agencyId}
                />
              </SectionWrapper>
              <SectionWrapper
                componentId={COMPONENT_IDS.enquiryForm}
                bottomBorder={false}
              >
                <InView
                  onActivate={trackContactEnquiryForm.trackEnquiryFormViewed}
                >
                  <ContactEnquiryFormWithFragment
                    agentIdV2={agentIdV2}
                    agencyId={agencyId}
                    contactId={contactId}
                    onSelectIntent={trackContactEnquiryForm.trackSelectIntent}
                    onSubmitIntent={trackContactEnquiryForm.trackSubmitIntent}
                    onSubmit={trackContactEnquiryForm.trackSubmit}
                    onSubmitSuccess={trackContactEnquiryForm.trackSubmitSuccess}
                    onSubmitError={trackContactEnquiryForm.trackSubmitError}
                  />
                </InView>
              </SectionWrapper>
              <SectionWrapper noStyles componentId={COMPONENT_IDS.map}>
                <AgencyProfileMapWithFragment agencyId={agencyId} />
              </SectionWrapper>
            </ShortlistProvider>
          </MediaQueryProvider>
        </div>

        {isEmbeddedApp ? null : (
          <ContactProfileFooterWithFragment
            agentIdV2={agentIdV2}
            agencyId={agencyId}
          />
        )}
      </>
    </ErrorBoundary>
  );
};

export default AgentProfilePage;

export const getServerSideProps: GetServerSideProps = async ({
  req,
  res,
  query,
}) => {
  const logger = getDomainWinstonLogger(__filename);
  const user = getUserDetails(req.user);

  const agentSlug = query.agentSlug; // shaun-stoker-762345
  if (typeof agentSlug !== 'string') {
    return {
      notFound: true,
    };
  }
  const slug = agentSlug.match(/\d+$/)?.pop();
  const contactId = parseInt(slug ?? '0', 10);

  if (!contactId) {
    return {
      notFound: true,
    };
  }

  const pageProps = JSON.parse(
    JSON.stringify({
      props: {
        // _app.js props
        serverInfo: res.locals.serverInfo,
        mixpanelToken: publicRuntimeConfig.MIXPANEL_TOKEN,

        isEmbeddedApp: req.isEmbeddedApp,

        // User data
        user,

        // page props
        isAgency: false,
        requestAppraisalUrl: joinUrls([
          publicRuntimeConfig.BASE_URL,
          publicRuntimeConfig.APPRAISAL_FUNNEL_PATH,
        ]),
        contactId,
      },
    }),
  );

  const apolloClient = initializeApollo();

  try {
    await getDataFromTree(
      <ApolloProvider client={apolloClient}>
        <AgentProfilePage {...pageProps.props} />
      </ApolloProvider>,
    );

    const { contactByContactId } = apolloClient.readQuery({
      query: AGENT_PROFILE_QUERY,
      variables: {
        contactId,
      },
    });

    if (!contactByContactId) {
      return {
        notFound: true,
      };
    }

    // redirect to canonical URL
    // contactByContactId.profileUrl seems to include domain and full path, we just want the slug
    const urlParts = contactByContactId.profileUrl?.split('/').filter(Boolean);
    if (urlParts?.length !== 0) {
      const canonicalSlug = urlParts.pop();
      if (
        canonicalSlug.includes(contactId.toString()) &&
        canonicalSlug !== agentSlug
      ) {
        return {
          redirect: {
            destination: `/real-estate-agent/${canonicalSlug}/`,
            permanent: true,
          },
        };
      }
    }

    pageProps.props.hideAppraisalCta = await isAgencyWithoutAppraisalProcess(
      contactByContactId.agencyId,
    );

    return addApolloState(apolloClient, pageProps);
  } catch (error: any) {
    logger.error('GraphQL SSR error', error);

    // Handle 404s
    if (
      // https://github.com/domain-group/fe-pa-trade-profile/blob/master/src/js/agency-profile.js#L77
      error?.message?.includes?.('Insufficient data')
    ) {
      return {
        notFound: true,
      };
    }

    throw error;
  }
};
